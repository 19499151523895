/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/mobile-detect@1.4.5/mobile-detect.min.js
 * - /npm/@glidejs/glide@3.6.2/dist/glide.min.js
 * - /npm/micromodal@0.4.10/dist/micromodal.min.js
 * - /npm/imask@7.6.1/dist/imask.min.js
 * - /npm/notyf@3.10.0/notyf.min.js
 * - /npm/translit-ed@1.0.1/dist/translit-ed.js
 * - /npm/photoswipe@5.4.4/dist/umd/photoswipe.umd.min.js
 * - /npm/photoswipe@5.4.4/dist/umd/photoswipe-lightbox.umd.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
